<template>
  <div class="container">
    <ProgressBar :currentStep="currentStep" />

    <IdentiteCandidat 
      v-if="currentStep === 1" 
      :formData="formData" 
      @update:formData="updateFormData"
      @next="nextStep" 
    />

    <ExperienceProfessionnelle 
      v-if="currentStep === 2" 
      :formData="formData" 
      @update:formData="updateFormData"
      @next="nextStep"
      @prev="prevStep" 
    />

    <ExperienceCommunautaire 
      v-if="currentStep === 3" 
      :formData="formData" 
      @update:formData="updateFormData"
      @next="nextStep"
      @prev="prevStep" 
    />

    <MotivationActivite 
      v-if="currentStep === 4" 
      :formData="formData" 
      @update:formData="updateFormData"
      @next="nextStep"
      @prev="prevStep" 
    />

    <JoindreFichiers 
      v-if="currentStep === 5" 
      :formData="formData" 
      @update:formData="updateFormData"
      @submit="submitForm"
      @prev="prevStep" 
    />
  </div>
</template>

<script>
import ProgressBar from './ProgressBar.vue'
import IdentiteCandidat from './IdentiteCandidat.vue'
import ExperienceProfessionnelle from './ExperienceProfessionnelle.vue'
import ExperienceCommunautaire from './ExperienceCommunautaire.vue'
import MotivationActivite from './MotivationActivite.vue'
import JoindreFichiers from './JoindreFichiers.vue'
  import { defineRule } from 'vee-validate'
import "intl-tel-input/build/css/intlTelInput.css";
  import intlTelInput from "intl-tel-input";
  import parsePhoneNumber from "libphonenumber-js";
  import {
    isValidPhoneNumber,
  } from 'libphonenumber-js'
var iti;
defineRule("telephone", (value) => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true;
    }
    // Check if international telephone numbers is valid
    if (!isValidPhoneNumber(iti.getNumber())) {
      return "Le numéro de téléphone n'est pas valide.";
    }
    return true;
  });
export default {
  name: 'FormulaireCandidature',
  inject: [
    "ToastSuccess",
    "ToastError",
    "currentYearUrl",
    "AlertWarning",
    "AlertSuccess",
    "AlertError",
  ],
  components: {
    ProgressBar,
    IdentiteCandidat,
    ExperienceProfessionnelle,
    ExperienceCommunautaire,
    MotivationActivite,
    JoindreFichiers
  },
  data() {
    return {
      currentStep: 1,
      formData: {
        nom: '',
        prenom: '',
        date_naissance: '',
        genre: '',
        email: '',
        adresse: '',
        situation: '',
        niveau_etude: '',
        domaine_formation: '',
        specialite: '',
        entite_formation: '',
        secteur_activite: '',
        details_activite: '',
        niveau_etude_prof: '',
        logiciel_ca: '',
        entreprise: '',
        entreprise_nom: '',
        structure: '',
        structure_travail: '',
        secteur_activite_exp: '',
        produit: '',
        produit_innovation: '',
        engagement_communautaire: '',
        groupes_communautaires: '',
        motivation: '',
        attentes: '',
        images: []
      }
    }
  },
  mounted() {
      let input = document.querySelector("#phone");
      iti = intlTelInput(input, {
        preferredCountries: ["bj", "bf", "ci", "dj", "ne", "sn", "tg", "ga"],
        initialCountry: "bj",
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.min.js",
      });
  
      let that = this;
      let appIsAlreadyMounted = false;
  
      document.querySelector(".iti").addEventListener("click", function () {
        if (!appIsAlreadyMounted) {
          appIsAlreadyMounted = !appIsAlreadyMounted;
          const phoneNumber = parsePhoneNumber(iti.getNumber());
          if (phoneNumber) {
            that.localFormData.phone = phoneNumber.formatNational();
          }
        }
      });
    },
  methods: {
    updateFormData(newData) {
      this.formData = { ...this.formData, ...newData }
    },
    nextStep() {
      if (this.currentStep < 5) this.currentStep++
    },
    prevStep() {
      if (this.currentStep > 1) this.currentStep--
    },
    submitForm() {
      // Ici, vous pouvez utiliser la même logique que dans votre méthode onSubmit originale
      let formData = new FormData()
      formData.append("step", 1);
      for (let key in this.formData) {
        if (key === 'images') {
          for (let i = 0; i < this.formData.images.length; i++) {
            formData.append("images[]", this.formData.images[i])
          }
        } else if (key === 'phone') {
          formData.append(key, iti.getNumber())
        } else {
          formData.append(key, this.formData[key])
        }
      }

      this.$http.post("new-candidacies", formData)
        .then(() => {
            this.AlertSuccess.fire({
                text: "Votre dossier de candidature a été envoyé avec succès !",
              });
          this.$router.push({ name: "Status" })
        })
        .catch((error) => {
          if (error.response.status == 422) {
              this.ToastError.fire({
                title: "Veuillez bien remplir le formulaire et réessayez.",
              });

              
            }
          });

    }
  }
}
</script>
<style scoped>
legend{
  margin-top: 15px;
  font-weight: bold;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .form-control,
  .form-select {
    line-height: 2;
  }
}

.section-title {
  border-bottom: 3px solid #3490dc;
}
</style>