<template>
    <div>
      <h3>Expérience communautaire</h3>
      <VeeForm :validation-schema="schema" @submit="onSubmit">
        <fieldset>
          <div class="mb-3">
            <label for="engagement_communautaire" class="form-label">Avez-vous un engagement communautaire ?</label>
            <Field name="engagement_communautaire" as="select" class="form-select" v-model="localFormData.engagement_communautaire">
              <option value="">Sélectionnez</option>
              <option value="oui">Oui</option>
              <option value="non">Non</option>
            </Field>
            <ErrorMessage name="engagement_communautaire" class="text-danger" />
          </div>
        </fieldset>
  
        <fieldset v-if="localFormData.engagement_communautaire === 'oui'">
          <div class="mb-3">
            <label for="groupes_communautaires" class="form-label">Dans quel(s) groupe(s) communautaire(s) militez-vous ?</label>
            <Field name="groupes_communautaires" type="text" class="form-control" v-model="localFormData.groupes_communautaires" />
            <ErrorMessage name="groupes_communautaires" class="text-danger" />
          </div>
        </fieldset>
  
        <button type="button" class="btn btn-secondary me-2" @click="$emit('prev')">Précédent</button>
        <button type="submit" class="btn btn-primary">Suivant</button>
      </VeeForm>
    </div>
  </template>
  
  <script>
  import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate'
  import { defineRule } from 'vee-validate'
  import { required } from '@vee-validate/rules'
  
  defineRule('required', required)
  defineRule("required_if_yes", (value, [inputField]) => {
  if (inputField == "oui") {
    if (!value) {
      return "Ce champs est obligatoire";
    }
  }

  return true;
});
  export default {
    name: 'ExperienceCommunautaire',
    components: {
      VeeForm,
      Field,
      ErrorMessage
    },
    props: ['formData'],
    emits: ['update:formData', 'next', 'prev'],
    data() {
      return {
        localFormData: { ...this.formData },
        schema: {
            engagement_communautaire: 'required',
            groupes_communautaires: 'required_if_yes:@produit',
        }
      }
    },
    methods: {
      onSubmit() {
        this.$emit('update:formData', this.localFormData)
        this.$emit('next')
      }
    }
  }
  </script>