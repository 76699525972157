<template>
  <div class="container px-sm-0 bg-light">
    <div class="row adjust-margin">
      <div class="col">
        <div class="container px-sm-5" v-if="this.userProfile.step >= 0 && this.userProfile.step < 1">
          <div class="alert alert-success fs-3 mt-4 text-center" role="alert">
            Les candidatures sont ouvertes.
          </div>
          <!-- <candidacy 
              v-if="currentStep == 1"
              v-on:to-next="currentStep"
              :previousData="step1"
              :step="userProfile.step">
          </candidacy> -->
          <new-candidacy-form
          v-if="currentStep == 1"
              :previousData="step1"
              :step="userProfile.step">
            </new-candidacy-form>
        </div>
        <div v-else-if="this.userProfile.step >= 1" class="container px-sm-5 my-4">
          <div class="row">
            <div class="col">
              <div class="alert alert-info text-center" role="alert">
                <span class="fs-3"
                  >Vous avez déjà soumis votre dossier de candidature.</span
                >
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-auto">
              <router-link :to="{ name: 'Status' }" class="btn btn-lg btn-primary">
                Suivre mon dossier
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Generality from "../components/EeiaGenerality.vue";
import NewCandidacyForm from "../components/NewCandidacyForm.vue";
//import Candidacy from "../components/Candidacy.vue";

export default {
  name: "NewCandidacy",
  inject: ["ToastSuccess", "ToastError", "currentYearUrl"],
  components: {
    NewCandidacyForm,
    //Candidacy
  },
  mounted() {
    // document.querySelector("body").style.backgroundColor = "#446eada1";
  },
  created() {
    this.getAllSteps();
  },
  data() {
    return {
      currentStep: 0,
      step1: {},
      userProfile: {},
      nativeCountry: "",
      //stepTwoCategory: "",
    };
  },
  methods: {
    getAllSteps() {
      this.$http.get("new-candidacies").then(async (result) => {
        await this.setAllLocalSteps(result);

        if (localStorage.getItem("currentStep")) {
          this.currentStep = parseInt(localStorage.getItem("currentStep"));
        } else {
          this.currentStep = 1;
        }
      });
    },
    async setAllLocalSteps(result) {
      this.step1 = result.data.step1;
      this.userProfile = result.data.userProfile;
      this.displayUserInformations(this.userProfile);
      //this.stepTwoCategory = result.data.step2.category
    },
    displayUserInformations(userProfile) {
      document.getElementById("navbarLastName").textContent =
        userProfile.lastName;
      document.getElementById("navbarFirstName").textContent =
        userProfile.firstName;
    },
    updateUserStep() {
      this.userProfile.step = 1;
      // document.getElementById("newCandidacyLink").textContent =
      //   "Modifier ma candidature";
      // this.$emit("update-nav");
      this.$router.push({ name: "Home" });
      localStorage.setItem("candidature_step", 1);
    },
    //updateStepTwoCategory(e) {
    //  this.stepTwoCategory = e
    //},
    updateNativeCountry(e) {
      this.nativeCountry = e;
    },
  },
};
</script>

<style scoped>
@media all and (max-width: 991px) {
  /* .adjust-margin {
    margin: 0px !important;
  } */
}
</style>