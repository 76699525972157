<template>
    <div class="progress-container">
      <div class="progress-bar">
        <div 
          class="progress" 
          :style="{ width: `${(currentStep - 1) * 25}%` }"
        ></div>
      </div>
      <div class="steps">
        <div 
          v-for="step in 5" 
          :key="step" 
          class="step" 
          :class="{ active: step <= currentStep, completed: step < currentStep }"
        >
          {{ step }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      currentStep: {
        type: Number,
        required: true
      }
    }
  }
  </script>
  
  <style scoped>
  .progress-container {
    margin-bottom: 2rem;
  }
  
  .progress-bar {
    width: 100%;
    height: 8px;
    background-color: #E6E6E6;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 10px;
  }
  
  .progress {
    height: 100%;
    background-color: #1D9ED9;
    transition: width 0.3s ease;
  }
  
  .steps {
    display: flex;
    justify-content: space-between;
  }
  
  .step {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #E6E6E6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #808285;
    transition: all 0.3s ease;
  }
  
  .step.active {
    background-color: #1D9ED9;
    color: white;
  }
  
  .step.completed {
    background-color: #F4D218;
    color: #1D9ED9;
  }
  </style>