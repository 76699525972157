<template>
  <div class="background">
    <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <!-- Bannière -->
      <div class="row py-5 bg-header" style="margin-bottom: 20px; position: relative;">
        <div class="col-12 pt-lg-5 mt-5 text-center" style="position: relative; z-index: 2;">
          <h1 class="display-4 text-white animated zoomIn mb-3"><strong>Contactez-Nous</strong></h1>
          <a href="/" class="h5 text-white" style="text-decoration: none;">Accueil</a>
          <!-- <i class="far fa-circle text-white px-2"></i> -->
          <span class="h5 text-white"> / </span>
          <span class="h5 text-white">Contact</span>
          <br /><br />
        </div>
      </div>

      <!-- <div class="banner">
        <img src="../static/Contact1.png" alt="Contact Banner" class="img-fluid" />
        <h1 class="banner-text">Contactez-Nous</h1>
      </div> -->

      <!-- Contenu principal -->
      <div class="container py-5">
        <div class="section-title text-center position-relative pb-3 mb-5 mx-auto animated" style="max-width: 600px;">
          <!-- <h5 class="fw-bold text-primary text-uppercase">Contact Us</h5> -->
          <h1 class="mb-0">Pour plus d’informations :</h1>
          <div class="title-underline"></div>
        </div>
        <div class="row g-5 mb-5">
          <div class="col-lg-4">
            <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
              <div class="d-flex align-items-center justify-content-center rounded"
                style="width: 60px; height: 60px; background-color: #1d9ed9;">
                <i class="fa fa-phone-alt text-white"></i>
              </div>
              <div class="ps-4">
                <!-- <h5 class="mb-2">Contact</h5> -->
                <h4 class="text-color mb-0">+229 69 83 39 43</h4>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.4s">
              <div class="d-flex align-items-center justify-content-center rounded"
                style="width: 60px; height: 60px; background-color: #1d9ed9;">
                <i class="fa fa-envelope-open text-white"></i>
              </div>
              <div class="ps-4">
                <!-- <h5 class="mb-2">Email</h5> -->
                <h4 class="text-color mb-0">info@benin-unipod.bj</h4>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.8s">
              <div class="d-flex align-items-center justify-content-center rounded"
                style="width: 60px; height: 60px; background-color: #1d9ed9;">
                <i class="fa fa-map-marker-alt text-white"></i>
              </div>
              <div class="ps-4">
                <!-- <h5 class="mb-2">Adresse</h5> -->
                <h4 class="text-color mb-0">Université d'Abomey-Calavi, Bénin</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-5">
          <div class="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
            <div class="contact-form row d-flex justify-content-center">
              <h1>Joignez-nous </h1>
              <span>Vous pouvez nous contacter via ce formulaire.
                Une réponse vous sera adressée dans les meilleurs délais.</span>
              <form v-if="!submitted" @submit.prevent="submitForm">
                <div class="form-fields">
                  <label for="name">Nom</label>
                  <input type="text" id="name" name="name" v-model="name">
                </div>
                <div class="form-fields">
                  <label for="email">Email</label>
                  <input type="email" id="email" name="email" v-model="email">
                </div>

                <div class="form-fields">
                  <label for="message">Message</label>
                  <textarea id="message" name="message" v-model="message"></textarea>
                </div>

                <button type="submit">Envoyer votre message</button>
              </form>
              <div v-if="submitted">
                <p>Merci pour votre message! Nous vous répondrons bientôt.</p>
              </div>
              <div v-if="error">
                <p>Error: {{ error }}</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
            <iframe class="position-relative rounded w-100 h-100"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.803581415368!2d2.3386598999999997!3d6.419278599999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1024a981b4d388d9%3A0x6e4d879d6acc9c1f!2sUNIPOD%20B%C3%A9nin!5e0!3m2!1sfr!2sbj!4v1709285716241!5m2!1sfr!2sbj"
              frameborder="0" style="min-height: 350px; border:0;" allowfullscreen="" aria-hidden="false"
              tabindex="0"></iframe>
          </div>
          <div class="row p-sm-4 pt-3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  inject: [
    "ToastSuccess",
    "AlertError",
  ],
  data() {
    return {
      email: '',
      message: '',
      submitted: false,
      error: '',
    };
  },
  methods: {
    submitForm() {
      const formData = {
        name: this.name,
        email: this.email,
        message: this.message
      };

      // Envoie de la requête POST à votre backend Laravel
      this.$http.post('contact/send', formData)
        .then(() => {
          // Réponse réussie, affichez un message de succès et réinitialisez les valeurs du formulaire
          this.ToastSuccess.fire({
            title: "Message envoyé !",
          });
          this.submitted = true;
          this.name = '';
          this.email = '';
          this.message = '';
        })
        .catch((error) => {
          if (error.response) {
            this.AlertError.fire({
              title: "Echec !",
              text: "Désolé, une erreur s'est produite. Veuillez réessayer.",
            });
          }
          this.submitted = false;
        });
    }
  }
};

const link = document.createElement('link');
link.rel = 'stylesheet';
link.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css';
document.head.appendChild(link);
</script>

<style scoped>
.background {
  background-color: #ffffff;
}

.bg-header {
  position: relative;
  background: url('../assets/img/bg-navbar.jpg') center/cover no-repeat;
  padding: 60px 0;
}

.bg-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1d9dd96f;
  pointer-events: none;
}

.form-fields {
  margin-bottom: 1rem;
}

label {
  width: 100%;
  display: block;
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 0.9rem;
}

input[type="text"],
input[type="email"] {
  width: 100%;
  border: 1px solid #ccc;
  font-size: 1rem;
  padding: 6px 10px;
  border-radius: 4px;
}

textarea {
  width: 100%;
  min-height: 200px;
  border: 1px solid #ccc;
  font-size: 1rem;
  padding: 6px 10px;
  border-radius: 4px;
}

button[type="submit"] {
  background-color: #1d9ed9;
  color: white;
  font-size: 1.0rem;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-weight: 500;
  transition: transform 0.3s ease;
  width: 100%;
}

button[type="submit"]:hover {
  background-color: #22b3f6;
}

button[type="submit"]:active {
  transform: scale(0.9);
}

.text-color {
  color: #1d9ed9;
}

/*** Section Title ***/
.title-underline {
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #1D9ED9;
  margin-top: 20px;
  animation: slideRight 3s linear infinite;
}

/* 
.banner {
  position: relative;
}

.banner img {
  margin-top: 60px;
  width: 100%;
  height: 30vh;
  object-fit: cover;
}

.banner-text {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 45px;
  color: #fff;
  font-style: italic;
}

.column {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.column h2 {
  color: #333;
}

.column i {
  margin-right: 10px;
}

.section-title {
  justify-content: center;
  align-items: center;
  color: #808285;
}

h1 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
} */

@keyframes slideRight {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }

  50% {
    transform: scaleX(1);
    transform-origin: left;
  }

  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
}

/* .title-underline {
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #1D9ED9;
  animation: slideLeft 3s linear infinite;
}

@keyframes slideLeft {
  0% {
    transform: scaleX(0);
    transform-origin: right;
  }
  50% {
    transform: scaleX(1);
    transform-origin: right;
  }
  100% {
    transform: scaleX(0);
    transform-origin: left;
  }
} */

@media (min-width: 768px) {
  .contact-column {
    height: 410px;
    overflow-y: auto;
  }

  .banner-text {
    font-size: 4rem;
  }

  .banner img {
    margin-top: 60px;
    width: 100%;
    height: auto;
  }
}

@media (min-width: 1280px) {
  .contact-column {
    height: 250px;
    overflow-y: auto;
  }

  .banner-text {
    font-size: 100px;
  }

  .banner img {
    margin-top: 60px;
    width: 100%;
    height: auto;
  }
}
</style>





<!-- <div class="row d-flex justify-content-center mx-4">
  <div class="col-md-6 mb-4">
    <div class="column contact-column">
      <h2><i class="fas fa-university"></i>Contact UAC</h2>
      <ul>
        <li>
          <u><i class="fas fa-user"></i></u><strong>M. Basile KOUNOUHEWA</strong>
          <ul>
            <li>
              <i class="fas fa-graduation-cap"></i> <strong style="color: #808285;">Professeur Titulaire
                (CAMES)</strong><br>
              <i class="fas fa-cogs"></i>Directeur du Laboratoire de Physique du
              Rayonnement,
              Responsable des formations d’Energies Renouvelables et Systèmes Energétiques (ERSE)
              de la Faculté des Sciences et Techniques (FAST) de l’Université d’Abomey-Calavi (UAC)
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>

  <div class="col-md-6 mb-4">
    <div class="column contact-column">
      <h2><i class="fas fa-building"></i>Contact PNUD</h2>
      <ul>
        <li>
          <u><i class="fas fa-user"></i></u><strong>M. André-Félix SOSSOU</strong>
          <ul> <br />
            <li>
              <i class="fas fa-briefcase"></i><strong style="color: #808285;">Chargé de Programme Exploration au PNUD
                et
                Coordonnateur du Laboratoire d’Accélération</strong>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div> -->