<template>
    <div>
      <h3>Joindre CV, Attestation ou Certificat</h3>
      <VeeForm :validation-schema="schema" @submit="onSubmit">
        <fieldset>
          <div class="mb-3">
            <label for="images" class="form-label">Choisissez vos fichiers :</label>
            <Field name="images" type="file" v-model="localFormData.images" multiple @change="handleFileUpload" />
            <ErrorMessage name="images" class="text-danger" />
          </div>
        </fieldset>
  
        <button type="button" class="btn btn-secondary me-2" @click="$emit('prev')">Précédent</button>
        <button type="submit" class="btn btn-primary">Soumettre</button>
      </VeeForm>
    </div>
  </template>
  
  <script>
  import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate'
  import { defineRule } from 'vee-validate'
  import { required } from '@vee-validate/rules'
  
  defineRule('required', required)
  
  export default {
    name: 'JoindreFichiers',
    components: {
      VeeForm,
      Field,
      ErrorMessage
    },
    props: ['formData'],
    emits: ['update:formData', 'submit', 'prev'],
    data() {
      return {
        localFormData: { ...this.formData },
        schema: {
          images: 'required'
        }
      }
    },
    methods: {
      handleFileUpload(event) {
        this.localFormData.images = event.target.files
      },
      onSubmit() {
        this.$emit('update:formData', this.localFormData)
        this.$emit('submit')
      }
    }
  }
  </script>