<template>
    <div>
      <h3>Motivation pour l'activité</h3>
      <VeeForm :validation-schema="schema" @submit="onSubmit">
        <fieldset>
          <div class="mb-3">
            <label for="motivation" class="form-label">Qu'est-ce qui vous motive à vous inscrire à cette activité ? (50 mots maximum) :</label>
            <Field name="motivation" as="textarea" class="form-control" v-model="localFormData.motivation" />
            <ErrorMessage name="motivation" class="text-danger" />
          </div>
  
          <div class="mb-3">
            <label for="attentes" class="form-label">Quelles sont vos attentes vis-à-vis de l'activité ?</label>
            <Field name="attentes" as="textarea" class="form-control" v-model="localFormData.attentes" />
            <ErrorMessage name="attentes" class="text-danger" />
          </div>
        </fieldset>
  
        <button type="button" class="btn btn-secondary me-2" @click="$emit('prev')">Précédent</button>
        <button type="submit" class="btn btn-primary">Suivant</button>
      </VeeForm>
    </div>
  </template>
  
  <script>
  import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate'
  import { defineRule } from 'vee-validate'
  import { required } from '@vee-validate/rules'
  
  defineRule('required', required)
  defineRule('max_words', (value, [limit]) => {
    if (!value) return true
    const wordCount = value.trim().split(/\s+/).length
    if (wordCount > limit) {
      return `Ce champ ne doit pas dépasser ${limit} mots.`
    }
    return true
  })
  
  export default {
    name: 'MotivationActivite',
    components: {
      VeeForm,
      Field,
      ErrorMessage
    },
    props: ['formData'],
    emits: ['update:formData', 'next', 'prev'],
    data() {
      return {
        localFormData: { ...this.formData },
        schema: {
          motivation: 'required|max_words:50',
          attentes: 'required'
        }
      }
    },
    methods: {
      onSubmit() {
        this.$emit('update:formData', this.localFormData)
        this.$emit('next')
      }
    }
  }
  </script>