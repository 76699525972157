<template>
  <div class="form-wrapper font">
    <main class="form-signin shadow-lg">
      <div class="text-center px-4">
        <div>
          <a href="/">
            <img src="../assets/img/eeia-logo.png" alt="" class="img-fluid" />
          </a>
        </div>
      </div>

      <h3 class="h3 mb-4 mt-4 text-center">
        <u>Conditions d'utilisation d'UNIPOD</u>
      </h3>

      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col">
            <p>L'accès à ce site Web, y compris ses sous-domaines, sites associés, applications mobiles et extranets
              (individuellement un « Site » et collectivement les « Sites ») et leur utilisation constituent une
              acceptation de votre part ou de l’entité que vous représentez des conditions d’utilisation présentées
              ci-après (les « Conditions d’utilisation »).</p>

            <p>Si vous n’acceptez pas ces Conditions d’utilisation, il est possible que vous ne puissiez pas accéder
              aux Sites ou les utiliser. D’autres conditions (y compris concernant l’âge minimum) (les « Conditions
              particulières ») peuvent s’appliquer et régir l’accès et l’utilisation de Contenus ou de Fonctionnalités
              de Sites spécifiques (tels que définis ci-dessous).</p><br>

            <h4>Copyright © UniPod</h4>
            <p>
              Le projet UNIPOD tient les sites Web pour rendre service à ceux qui souhaitent y accéder (les «
              Utilisateurs »). Les renseignements qui y figurent ne sont présentés qu’à titre d’information. À moins
              qu’une des Conditions particulières n’en dispose autrement, l’UniPod autorise les Utilisateurs à
              visiter les Sites et à télécharger ou copier des informations et des documents et contenus
              (collectivement les « Contenus ») à des fins personnelles et non commerciales, sans pour autant leur
              octroyer le droit de revendre ou de distribuer les Contenus ou de s’en servir pour constituer ou créer
              des ouvrages dérivés, ladite autorisation étant subordonnée au respect des Conditions d’utilisation
              ainsi que des restrictions spécifiques pouvant s’appliquer à un Contenu spécifique des Sites. Les Sites
              sont administrés par l’UniPod.
            </p>

            <p>L’Utilisateur peut devoir s’inscrire et/ou de créer un compte d’Utilisateur pour accéder à certaines
              sections des Sites, à des Contenus ou des Fonctionnalités des Sites, ou pour les utiliser. L’Utilisateur
              ne peut en aucun cas utiliser le compte d’un autre Utilisateur sans autorisation. En s’inscrivant ou en
              créant un compte d’Utilisateur, ce dernier déclare et garantit que i) il est majeur ou a obtenu le
              consentement légal d’un de ses parents ou de son tuteur et possède pleinement les facultés et
              aptitudes pour respecter les présentes Conditions d’utilisation et s’y conformer ; et ii) s’il représente
              une personne morale, il a le pouvoir de lier ladite personne morale aux présentes Conditions
              d’utilisation. L’Utilisateur est responsable de la confidentialité du mot de passe et autres
              informations de connexion pertinentes, et informe l’UniPod si, à un moment quelconque, il estime
              que son mot de passe ou ses informations de connexion ont été compromis et/ou sont utilisés à
              mauvais escient par quelqu’un d’autre, et prend les mesures nécessaires et/ou exigées par l’UniPod
              pour empêcher une telle utilisation abusive. L’Utilisateur est responsable de toutes les activités
              entreprises sous son compte d’Utilisateur.</p>

            <p>Sauf indication contraire expresse, les constatations, interprétations et conclusions qui font partie
              des Contenus des Sites sont celles des différents fonctionnaires de l’UniPod et consultants ou
              conseillers auprès de ce dernier qui ont participé à l’élaboration desdits Contenus, et elles ne
              correspondent pas nécessairement aux vues de l’UniPod ou de ses États membres. Les appellations
              employées sur les Sites et la présentation des Contenus qui y figurent n’impliquent de la part de
              l’UniPod aucune prise de position quant au statut juridique ou à l’état de développement des
              territoires, pays, villes ou zones, ou de leurs autorités, ni quant au tracé de leurs frontières ou
              limites.</p>

            <p>Les contenus de tiers présentés sur les Sites ne peuvent être utilisés qu’avec le consentement de
              leurs propriétaires ou autrement dans les conditions autorisées par la législation applicable, selon le
              cas. Aucune disposition des présentes Conditions d’utilisation n’est réputée conférer à une tierce
              partie quelconque des droits ou des avantages sur de tels contenus.</p>

            <p>Certaines applications mobiles proposées directement ou indirectement par l’UniPod peuvent être
              téléchargées à partir de « magasins d’applications » tiers, y compris, sans s’y limiter, iTunes et
              Google Play, et, à ce titre, elles sont soumises aux conditions distinctes des fournisseurs de services
              tiers concernés. Il vous incombe de vérifier les politiques de confidentialité et les conditions de
              service et d’utilisation de tous les sites, magasins et services tiers avant de télécharger ou d’utiliser
              les applications mobiles de l’UniPod.</p><br>

            <h4>Fonctionnalités des Sites et Contributions</h4>
            <p>
              Le Sites peuvent contenir des babillards électroniques, des salons de discussion, des accès à des
              listes de diffusion ou d’autres moyens de messagerie ou de communication (collectivement, les «
              Fonctionnalités des Sites »). L’Utilisateur consent à n’utiliser les Fonctionnalités des Sites que pour
              envoyer et recevoir des informations, du contenu, des messages et des documents qui soient
              appropriés et aient un rapport avec la Fonctionnalité du Site en question. À titre d’exemple, et sans
              s’y limiter, l’Utilisateur s’engage à s’abstenir, lorsqu’il utilise des Fonctionnalités des Sites, de se
              livrer aux activités suivantes&nbsp;:
            </p>
            <ul>
              <li>Harceler, maltraiter, menacer ou intimidée autrui de quelque manière que ce soit.
              </li>
            </ul>
            <ul>
              <li>Diffuser ou publier tout contenu offensant, diffamatoire, obscène, indécent, ou illégal.
              </li>
            </ul>
            <ul>
              <li>Utiliser les Fonctionnalités des Sites dans un but illégal, ou pour transmettre, publier ou distribuer
                tout matériel ou information à des fins illégales.
              </li>
            </ul>
            <ul>
              <li>Télécharger des fichiers qui contiennent des logiciels ou tout autre matériel protégé par des droits
                de propriété intellectuelle, à moins que l’Utilisateur ne possède ou ne contrôle les droits y afférents
                ou qu’il n’ait reçu toutes les autorisations nécessaires.
              </li>
            </ul>
            <ul>
              <li>Télécharger des fichiers qui contiennent des virus, des fichiers corrompus ou tout autre logiciel ou
                programme similaire qui pourrait endommager le fonctionnement normal d’un ordinateur ou d’un
                réseau informatique.
              </li>
            </ul>
            <ul>
              <li>Solliciter des autres Utilisateurs ou autrement recueillir des informations les concernant, y compris
                des adresses électroniques, sans leur consentement.
              </li>
            </ul>
            <ul>
              <li>Tenter d’entraver, de désactiver ou de surcharger les serveurs ou les réseaux connectés aux
                Fonctionnalités des Sites, ou de violer les procédures, politiques ou règlements de ces réseaux.
              </li>
            </ul>
            <ul>
              <li>Fournir des informations fausses ou trompeuses.
              </li>
            </ul>

            <p>L’Utilisateur s’engage à ne pas manipuler ou intervenir dans le contenu des Fonctionnalités des Sites
              et reconnaît que les modérateurs désignés par l'UNIPOD peuvent surveiller ces Fonctionnalités.
              Toutefois, l'UniPod ne garantit ni le contrôle ni l’examen des Fonctionnalités des Sites.</p>

            <p>Les Sites peuvent également permettre aux Utilisateurs de fournir des commentaires, suggestions,
              idées ou autres informations (les « Contributions ») à certaines Fonctionnalités des Sites. En
              fournissant des Contributions, l’Utilisateur accorde à l'UniPod une licence mondiale, perpétuelle,
              irrévocable, transférable, sous-licenciable, gratuite et non exclusive permettant à l'UniPod d’utiliser,
              reproduire, modifier, distribuer, afficher publiquement et créer des œuvres dérivées de ces
              Contributions, dans tous les formats et sur tous les supports, connus ou à développer
              ultérieurement, sans aucune limitation ni obligation à l’égard de l’Utilisateur. L’Utilisateur déclare et
              garantit qu’il détient ou contrôle tous les droits sur ses Contributions nécessaires pour qu’il accorde
              à ce titre les droits accordés par la présente section, que ses Contributions sont exactes, que
              l’utilisation de ses Contributions par le biais des Fonctionnalités des Sites ne viole pas les droits de
              tiers et qu’il indemnise l'UniPod pour toute réclamation découlant de ses Contributions.</p>
            <p></p><br>

            <h4>Déni de responsabilité et garantie</h4>

            <p>
              LES SITES ET LEURS CONTENUS SONT FOURNIS « TELS QUELS » SANS GARANTIE D’AUCUNE SORTE,
              EXPRESSE OU IMPLICITE. DANS TOUTE LA MESURE PERMISE PAR LA LOI APPLICABLE, L’UNIPOD
              DÉCLINE TOUTE GARANTIE, EXPRESSE OU IMPLICITE, Y COMPRIS, SANS S’Y LIMITER, LES GARANTIES
              IMPLICITES DE QUALITÉ MARCHANDE, D’ADÉQUATION À UN USAGE PARTICULIER OU D’ABSENCE DE
              CONTREFAÇON. L’UNIPOD NE GARANTIT PAS QUE LES SITES OU LEUR CONTENU RÉPONDENT À VOS
              EXIGENCES OU QUE LEUR ACCÈS OU LEUR UTILISATION SOIT ININTERROMPU OU EXEMPT
              D’ERREURS, QUE LES DÉFAUTS SERONT CORRIGÉS, OU QUE LES SITES OU LES SERVEURS QUI LES
              RENDENT DISPONIBLES SONT EXEMPTS DE VIRUS OU D’AUTRES ÉLÉMENTS NUISIBLES. L'UNIPOD NE
              DONNE AUCUNE GARANTIE QUANT À L’UTILISATION OU AUX RÉSULTATS DE L’UTILISATION DES
              CONTENUS DES SITES EN TERMES D’EXACTITUDE, DE PRÉCISION, D’UTILITÉ OU AUTRE.
            </p><br>

            <p>VOTRE UTILISATION DES SITES ET DE LEUR CONTENU EST À VOS PROPRES RISQUES. EN CAS DE
              DÉFAILLANCE DE L’ÉQUIPEMENT, DE VOTRE SYSTÈME OU DE TOUT LOGICIEL, AINSI QUE DE
              L’INCAPACITÉ D’ACCÉDER AUX SITES, DE TOUT DÉFAUT, DE LA PERTE DE DONNÉES OU DE TOUTE
              AUTRE PERTE DÉCOULANT DE L’UTILISATION DES SITES OU DE LEUR CONTENU, L’UNIPOD, SES
              FILIALES ET LEURS DIRIGEANTS, EMPLOYÉS, AGENTS, PARTENAIRES ET LICENCIÉS NE SERONT EN
              AUCUN CAS RESPONSABLES.</p><br>

            <p>CERTAINES JURIDICTIONS N’AUTORISENT PAS L’EXCLUSION DE CERTAINES GARANTIES, OU
              L’EXCLUSION OU LA LIMITATION DE RESPONSABILITÉ POUR LES DOMMAGES INDIRECTS OU
              ACCESSOIRES. PAR CONSÉQUENT, CERTAINES DES LIMITATIONS CI-DESSUS PEUVENT NE PAS
              S’APPLIQUER À VOUS. LA RESPONSABILITÉ TOTALE DE L’UNIPOD, DE SES FILIALES ET DE LEURS
              DIRIGEANTS, EMPLOYÉS, AGENTS, PARTENAIRES ET LICENCIÉS POUR TOUTES RÉCLAMATIONS,
              DOMMAGES OU PERTES, Y COMPRIS LES DOMMAGES DIRECTS, INDIRECTS, ACCESSOIRES,
              CONSÉCUTIFS OU PUNITIFS, QU’ILS SOIENT CONTRACTUELS OU DÉLICTUEUX, NE PEUT EXCÉDER LE
              MONTANT QUE VOUS AVEZ PAYÉ, LE CAS ÉCHÉANT, POUR L’UTILISATION DES SITES OU LEUR
              CONTENU.</p><br>

            <h4>Généralités</h4>
            <p>
              UniPod se réserve le droit d'ajouter, modifier, améliorer et/ou mettre à jour périodiquement les
              Sites, les Contenus des Sites et les Fonctionnalités des Sites sans préavis.
            </p>
            <p>
              UniPod se réserve le droit exclusif, à sa seule discrétion, de :
            </p>

            <span>(i) Modifier, limiter ou fermer les Sites, cesser la publication de tout Contenu ou mettre fin à des
              Fonctionnalités des Sites à quelque égard que ce soit ; et</span><br>
            <span>(ii) Modifier les présentes Conditions
              générales d’utilisation ou des Conditions particulières.</span>

            <p>
              UniPod n’a aucune obligation de prendre en considération les besoins de tout Utilisateur à cet égard
              ni d’alerter tout Utilisateur de telles actions.
            </p>

            <p>UniPod se réserve le droit, à sa seule discrétion, de refuser à tout Utilisateur l’accès aux Sites ou à
              toute partie de ceux-ci sans préavis, et de suspendre, révoquer ou cesser de fournir des comptes
              d’Utilisateur si l’Utilisateur ne respecte pas les Conditions d’utilisation ou des Conditions
              particulières, et de supprimer, pour quelque raison que ce soit et sans préavis, toute Contribution.</p>
            <br>

            <p>Le nom, l’emblème et l’abréviation d'UniPod sont la propriété exclusive d'UniPod et sont protégés
              par le droit international. Leur utilisation non autorisée est interdite et ils ne peuvent être reproduits
              ou utilisés de quelque manière que ce soit sans l’autorisation écrite préalable d'UniPod.</p>

            <p>La mention ou l’omission de sociétés spécifiques, de leurs produits ou noms de marque sur les Sites
              n’implique aucune approbation de ceux-ci de la part d'UniPod.</p>

            <p>Les Utilisateurs s’abstiennent de supprimer, masquer ou modifier les mentions légales affichées dans
              les Contenus ou les Fonctionnalités des Sites ou avec eux.</p><br>

            <p>UniPod se réserve le droit d’exercer tous les recours prévus par la législation pour toute violation des
              présentes Conditions d’utilisation, y compris les droits d'UniPod sur les Sites, les Contenus, les
              Fonctionnalités des Sites et les Contributions. Aucune renonciation de la part d'UniPod à une des
              dispositions des présentes Conditions d’utilisation ne lui sera opposable si elle n’a pas été effectuée
              par écrit et signée par un représentant dûment habilité d'UniPod.</p><br>

            <p>Les présentes Conditions d’utilisation et tout litige qui en découlerait sont exclusivement régis par
              les principes du droit international, à l’exclusion de tout système de droit national ou infranational
              unique.</p><br>

            <p>Tout litige ou toute controverse ou réclamation découlant de ces Conditions d’utilisation ou en
              rapport avec elles, ou toute violation, résiliation ou invalidité de celles-ci, ou en rapport avec
              l’utilisation des Sites, des Contenus et des Fonctionnalités des Sites par l’Utilisateur ou de tout
              contenu disponible dans ceux-ci et des conditions particulières qui leur sont applicables sont réglés

              par arbitrage conformément au Règlement d’arbitrage en vigueur de la CNUDCI. Le tribunal arbitral
              est habilité à ordonner :</p>

            <span>(i) Le retour ou la destruction de marchandises ou de biens, corporels ou incorporels, ou
              d’informations fournies en vertu des présentes Conditions d’utilisation ; ou</span><br>
            <span>(ii) Que toute autre
              mesure de protection soit prise à l’égard des biens, des services ou de tout autre bien, corporel ou
              incorporel, ou de toute information confidentielle fournie en vertu des présentes, selon le cas, le
              tout conformément à l’autorité du tribunal arbitral en application des dispositions de l’article 26 («
              Mesures provisoires ») et de l’article 34 (« Forme et effet de la sentence ») du Règlement d’arbitrage
              de la CNUDCI.</span><br><br>

            <p>Le tribunal arbitral n’a pas le pouvoir d’infliger des dommages-intérêts punitifs. UniPod et
              l’Utilisateur sont liés par toute décision découlant de cet arbitrage qui est considérée comme
              définitive.</p>
            <p>Dans le cas où il existe plus d’une version linguistique des présentes Conditions d’utilisation et une
              incohérence entre les versions, la version anglaise prévaudra toujours.</p>
            <p>Aucune disposition contenue dans les présentes ne constitue ni ne peut être considérée comme une
              limitation des privilèges et immunités d'UniPod, ni comme une renonciation à ces privilèges et
              immunités, que UniPod se réserve expressément.</p><br>

            <h4>Confidentialité sur Internet</h4>

            <p>
              Votre vie privée lors de l’utilisation des Sites d&#39;UniPod est de la plus haute importance pour UniPod.
              Cette section décrit la politique d'UniPod concernant la collecte et le partage des renseignements
              concernant les utilisateurs via les Sites.
            </p>

            <h5><i>Quels renseignements UniPod recueille-t-il ?</i></h5><br>
            <h5><i>Utilisation anonyme des sites Web</i></h5>
            <p>
              D’une manière générale, vous pouvez naviguer sur les Sites sans nous dire qui vous êtes ni révéler
              aucun renseignement personnel vous concernant. Nous recueillons des données de navigation
              générales à partir des journaux de serveur standard. Il s’agit notamment de votre adresse IP
              (protocole Internet), votre nom de domaine, votre type de navigateur, votre système d’exploitation
              et des informations telles que le site Web qui vous a référé à nous, les fichiers que vous avez
              téléchargés, les pages que vous visitez et les dates et heures de ces visites.
            </p><br>

            <h5><i>Collecte de renseignements personnels</i></h5>
            <p>
              Lorsque vous accédez aux Sites, il peut vous être demandé de fournir des renseignements
              personnels. Ces renseignements sont recueillis uniquement à votre connaissance et avec votre
              permission et sont conservés dans diverses bases de données d'UniPod.
            </p>

            <p>Par exemple, si vous vous abonnez à un bulletin d’information, vous connectez à certains Sites
              d'UniPod, demandez des informations, communiquez des commentaires, postulez à un emploi,
              rejoignez un groupe de discussion ou rejoignez une liste de diffusion électronique, vous devrez
              fournir des renseignements personnels tels que votre nom, votre adresse postale et votre adresse
              électronique. Si vous postulez un emploi, il vous sera demandé de fournir des renseignements sur
              votre emploi antérieur et votre parcours académique, entre autres.</p>

            <p>Certains Sites ayant des exigences spécifiques pour recueillir des renseignements personnels
              peuvent être régis par une politique de confidentialité spécifique à ce Site. Dans ces cas, les
              politiques spécifiques aux Sites seront complémentaires à la présente politique générale de
              confidentialité d'UniPod et fourniront des informations sur la confidentialité et l’utilisation des
              renseignements personnels.</p>

            <p>La participation à des groupes de discussion électroniques peut signifier que d’autres participants du
              groupe (y compris autres que des employés d'UniPod) verront les renseignements personnels que
              vous avez fournis volontairement. Pour les groupes de discussion ouverts, ces renseignements
              seront publics.</p><br>


            <h5><i>Utilisation de cookies</i></h5>

            <p>Un cookie est une donnée sauvegardée dans le disque dur de l’Utilisateur et contenant des
              informations sur l’Utilisateur. L’utilisation d’un cookie n’est en aucun cas liée à des renseignements
              personnels lorsque l’Utilisateur est sur les Sites.</p>

            <p>L’installation d’un cookie permet à UniPod de se souvenir de vous la prochaine fois que vous
              visiterez les Sites et, par conséquent, il n’aura pas à vous poser des questions auxquelles vous avez
              déjà répondu.</p>

            <p>Vous êtes toujours libre de refuser nos cookies si votre navigateur le permet, mais dans ce cas, vous
              ne pourrez peut-être pas utiliser certaines Fonctionnalités des Sites et devrez peut-être ressaisir des
              informations plus fréquemment pour utiliser certains services sur les Sites.</p><br>

            <h4>Que fait UniPod des informations qu’il recueille ?</h4><br>

            <h5><i>Utilisation anonyme du Web</i></h5>
            <p>Les informations recueillies lorsque vous parcourrez le Site « <a
                href="https://benin-unipod.bj">https://benin-unipod.bj</a> » sont
              utilisées pour analyser les tendances et l’utilisation des Sites d'UniPod et dans le but d’améliorer
              l’utilité de ces Sites. Ces informations n’ont aucun rapport avec les renseignements personnels.
              Toutefois, si vous vous êtes inscrit auprès d'UniPod dans le cadre de toute activité sur les Sites, les
              informations que nous recueillons sur votre utilisation normale du Web vous seront rattachées.</p><br>

            <h5><i>Utilisation des renseignements personnels</i></h5>
            <p>UniPod peut utiliser les renseignements personnels que vous fournissez pour, entre autres :</p>

            <div class="styled-list">
              <ul>
                <li>Vous contacter – soit en réponse à une question ou à une suggestion, soit pour vous envoyer des
                  bulletins d’information, des documents, des publications, etc. ;</li>
              </ul>
              <ul>
                <li>Gérer et traiter votre demande d’emploi ;</li>
              </ul>
              <ul>
                <li>Confirmer vos inscriptions sur les Sites ;</li>
              </ul>
              <ul>
                <li>Conserver votre profil et vos préférences en ligne pour de futures visites sur les Sites ;</li>
              </ul>
              <ul>
                <li>Vous aider à trouver rapidement des informations pertinentes en fonction de vos centres d’intérêt
                  et nous aider à créer le contenu de Sites le plus pertinent pour vous ;</li>
              </ul>
              <ul>
                <li>Entreprendre des analyses statistiques.</li>
              </ul>
            </div>

            <p>La collecte, la conservation et l’utilisation de renseignements personnels sont soumises aux
              réglementations, règles, politiques et procédures d'UniPod et aux principes de protection des
              données personnelles et de confidentialité qui peuvent être consultés ici.</p><br>

            <h5><i>Que se passe-t-il si je ne souhaite pas fournir de renseignements personnels ?</i></h5>

            <p>La fourniture de renseignements personnels sur les Sites est facultative. Si vous choisissez de ne pas
              fournir de renseignements personnels, vous pourrez toujours naviguer sur les Sites et les utiliser,
              mais vous ne pourrez pas entreprendre certaines actions telles que faire des dons, vous inscrire à un
              bulletin d’information ou postuler un emploi.</p><br>

            <h5><i>Choix de ne pas communiquer des renseignements personnels et modification
                desdits renseignements</i></h5>

            <p>À tout moment, vous pouvez annuler ou modifier vos renseignements personnels en revenant à la
              page Web où vous les avez fournis pour la première fois et en contactant le point focal de cette page
              ou du service concerné d'UniPod ou, dans certains cas, en accédant à votre compte pour y faire les
              modifications voulues.</p>

            <p>Vous pouvez choisir de recevoir des communications telles que des bulletins d’information et
              d’autres documents d'UniPod. Dans ce cas, vous aurez la possibilité à tout moment de choisir de ne
              plus recevoir ces communications en accédant aux Sites et en modifiant vos préférences de contact
              ou en cliquant sur le lien de désabonnement fourni au bas des courriels.</p><br>

            <h5><i>Sécurité</i></h5>

            <p>Nous ne partageons aucun renseignement personnel fourni volontairement sur les Sites avec de
              tierces parties sans le consentement préalable du propriétaire du renseignement. Cependant, nous
              partageons certains renseignements concernant l’Utilisateur avec nos partenaires, certains agents
              ou prestataires de services, par exemple, des sous-traitants travaillant à la maintenance de nos sites
              Web, aux seules fins de nous aider à effectuer notre travail.</p>

            <p>UniPod utilise une panoplie de techniques et de mesures de sécurité correspondant aux meilleures
              pratiques pour protéger les renseignements conservés dans son système contre la perte, l’utilisation
              abusive, l’accès ou la divulgation non autorisés, l’altération ou la destruction. Cependant, UniPod
              n’assume aucune responsabilité quant à la sécurité des renseignements.</p><br>

            <h5><i>Notification des modifications</i></h5>
            <p>Si l’UniPod décide de modifier sa politique de confidentialité, il publiera ces modifications sur cette
              page afin que vous soyez toujours au courant du type d’informations que nous recueillons et de la
              manière dont nous les utilisons.</p><br>

            <h4>Contactez-nous</h4>

            <p>Pour toutes questions ou préoccupations concernant les Conditions d’utilisation ou la
              politique de confidentialité d'UniPod, veuillez <a href="https://benin-unipod.bj/contact">nous
                contacter</a>.</p>
            
          </div>
        </div>
      </div>

      <div class="text-center fs-6 mt-4">
        <img src="../assets/img/logo_be_vallet_pnud.png" height="100" alt="" />
        <p class="mb-1 text-muted">
          &copy; Unipod - PNUD 2023–<span id="currentYear">2024</span>
        </p>
        <div class="mb-3 text-muted">
          <ul class="list-inline">
            <li class="list-inline-item">
              <router-link to="/register">Inscription</router-link>
            </li>
            <li class="list-inline-item">
              <router-link to="/login">Connexion</router-link>
            </li>
          </ul>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "CGU",
  inject: ["currentYearUrl"],
  components: {},
  mounted() {
    document.querySelector("body").style.backgroundColor = "#446ead";
    fetch(this.currentYearUrl)
      .then((result) => {
        result.text().then(function (text) {
          document.getElementById("currentYear").textContent = text;
        });
      })
      .catch(() => {
        document.getElementById("currentYear").textContent = "2025";
      });
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  /* background-image: var(--bs-gradient)!important; */
  background-color: #446ead !important;
  font-size: 20px;
}

.styled-list ul {
  list-style-type: none;
}

.styled-list li::before {
  content: "-";
  margin-right: 5px;
}

/* .font{
  font-family: 'proxima-nova', sans-serif;
} */

@media (min-width: 768px) {
  .form-control {
    line-height: 2.5;
  }
}

.btn-group-lg>.btn,
.btn-lg {
  padding: 0.5rem 1.5rem !important;
  font-weight: bold !important;
}

.form-wrapper {
  width: 100%;
  padding: 15px;
  max-width: 800px;
  margin: auto;
  margin-top: 11vh;
  margin-bottom: 8vh;
}

@media (max-width: 575.98px) {
  .form-wrapper {
    margin-top: 7.5vh !important;
    margin-bottom: 4vh !important;
  }

  .form-signin {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.form-signin {
  padding: 15px;
  width: 100%;
  background: white;
  border-radius: 8px;
}

.col p {
  text-align: justify;
}
</style>
