<template>
    <div>
      <h3>Identité du candidat</h3>
      <VeeForm :validation-schema="schema" @submit="onSubmit">
        <div class="mb-3">
          <label for="nom" class="form-label">Nom :</label>
          <Field name="nom" type="text" class="form-control" v-model="localFormData.nom" placeholder="Saisissez votre nom"
          />
          <ErrorMessage name="nom" class="text-danger" />
        </div>
  
        <div class="mb-3">
          <label for="prenom" class="form-label">Prénoms :</label>
          <Field name="prenom" type="text" class="form-control" v-model="localFormData.prenom" placeholder="Saisissez votre prénom"/>
          <ErrorMessage name="prenom" class="text-danger" />
        </div>
  
        <div class="mb-3">
          <label for="date_naissance" class="form-label">Date de naissance :</label>
          <Field name="date_naissance" type="date" class="form-control" v-model="localFormData.date_naissance" />
          <ErrorMessage name="date_naissance" class="text-danger" />
        </div>
  
        <div class="mb-3">
          <label for="genre" class="form-label">Genre :</label>
          <Field name="genre" as="select" class="form-select" v-model="localFormData.genre">
            <option value="">Sélectionnez</option>
            <option value="homme">Homme</option>
            <option value="femme">Femme</option>
            <option value="je préfère ne pas préciser">Je préfère ne pas préciser</option>
          </Field>
          <ErrorMessage name="genre" class="text-danger" />
        </div>
  
        <div class="mb-3">
          <label for="email" class="form-label">Adresse électronique :</label>
          <Field name="email" type="email" class="form-control" v-model="localFormData.email" placeholder="Saisissez votre Email"/>
          <ErrorMessage name="email" class="text-danger" />
        </div>
  
        <div class="mb-3">
          <label for="phone" class="form-label">Téléphone :</label>
          <Field
            name="phone"
            type="text"
            label="téléphone"
            v-slot="{ field, errorMessage }"
          >
            <input
              id="phone"
              v-bind="field"
              v-model="localFormData.phone"
              placeholder="Entrer votre numéro de téléphone ici."
              class="form-control"
              :class="{ 'is-invalid': errorMessage }"
            />
            <ErrorMessage
              name="phone"
              as="div"
              class="invalid-feedback"
              :class="{ 'd-block': errorMessage }"
            />
          </Field>
        </div>
  
        <div class="mb-3">
          <label for="adresse" class="form-label">Adresse géographique actuelle :</label>
          <Field name="adresse" as="textarea" class="form-control" v-model="localFormData.adresse" placeholder="Entrez votre position geographique"/>
          <ErrorMessage name="adresse" class="text-danger" />
        </div>
  
        <button type="submit" class="btn btn-primary">Suivant</button>
      </VeeForm>
    </div>
  </template>
  
  <script>
  import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate'
  import { defineRule } from 'vee-validate'
  import { required, email } from '@vee-validate/rules'
  
  import "intl-tel-input/build/css/intlTelInput.css";
  import intlTelInput from "intl-tel-input";
  import parsePhoneNumber from "libphonenumber-js";
  import {
    isValidPhoneNumber,
  } from 'libphonenumber-js'
  var iti;
  
  defineRule('required', required)
  defineRule('email', email)
  defineRule("telephone", (value) => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true;
    }
    // Check if international telephone numbers is valid
    if (!isValidPhoneNumber(iti.getNumber())) {
      return "Le numéro de téléphone n'est pas valide.";
    }
    return true;
  });
  
  import { setLocale } from "@vee-validate/i18n";
  import { configure } from "vee-validate";
  import { localize } from "@vee-validate/i18n";
  import fr from "@vee-validate/i18n/dist/locale/fr.json";
  configure({
    generateMessage: localize({
      fr,
    }),
  });
  setLocale("fr");
  
  export default {
    name: 'IdentiteCandidat',
    components: {
      VeeForm,
      Field,
      ErrorMessage
    },
    props: ['formData'],
    emits: ['update:formData', 'next'],
    data() {
      return {
        localFormData: { ...this.formData },
        schema: {
          nom: 'required',
          prenom: 'required',
          date_naissance: 'required',
          genre: 'required',
          email: 'required|email',
          phone: 'required|telephone',
          adresse: 'required'
        }
      }
    },
    mounted() {
      let input = document.querySelector("#phone");
      iti = intlTelInput(input, {
        preferredCountries: ["bj", "bf", "ci", "dj", "ne", "sn", "tg", "ga"],
        initialCountry: "bj",
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.min.js",
      });
  
      let that = this;
      let appIsAlreadyMounted = false;
  
      document.querySelector(".iti").addEventListener("click", function () {
        if (!appIsAlreadyMounted) {
          appIsAlreadyMounted = !appIsAlreadyMounted;
          const phoneNumber = parsePhoneNumber(iti.getNumber());
          if (phoneNumber) {
            that.localFormData.phone = phoneNumber.formatNational();
          }
        }
      });
    },
    methods: {
      onSubmit() {
        this.$emit('update:formData', this.localFormData)
        this.$emit('next')
      }
    }
  }
  </script>
  